<template>
  <AddPushCardCom @saveSuccess="saveSuccess" scene="material" />
</template>
<script>
  /**
   * @description 资料库创建卡片
   */
  import AddPushCardCom from '../../PlatformModule/PushCard/addCard.vue';
  export default {
    name: 'AddPushCardMgr',
    components: {
      AddPushCardCom
    },
    methods: {
      saveSuccess() {
        this.$router.replace({
          path: '/interactive/pushCard'
        });
      }
    }
  };
</script>
