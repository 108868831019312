<template>
  <div class="card-add-page">
    <pageTitle :pageTitle="$route.query.cardId ? '编辑卡片' : '创建卡片'"></pageTitle>
    <PushCardCreate
      :env="env"
      :cardId="cardId"
      :webinarId="$route.query.webinarId"
      :scene="scene"
      @saveSuccess="saveSuccess"
    />
  </div>
</template>
<script>
  import { PushCardCreate } from '@vhcl/push-card';
  import { isEmbed, embedRoomId } from '@/utils/utils.js';
  import PageTitle from '@/components/PageTitle';
  export default {
    name: 'AddPushCardCom',
    components: {
      PageTitle,
      PushCardCreate
    },
    props: {
      scene: {
        type: String,
        default: 'webinar' // webinar 活动层级；material 资料库
      }
    },
    data() {
      return {
        isEmbed: isEmbed(),
        embedRoomId: embedRoomId(),
        env: process.env.VUE_APP_ENV,
        cardId: this.$route.query.cardId || ''
      };
    },
    methods: {
      saveSuccess() {
        this.$emit('saveSuccess');
      }
    }
  };
</script>

<style lang="less" scoped>
  .card-add-page {
    .card-create-edit {
      display: flex;
      background: #ffffff;
      border-radius: 4px;
    }
    .vh-form-item {
      max-width: 462px;
    }
    .margin32 {
      margin-bottom: 32px;
    }
    .preview {
      margin-left: 77px;
    }
  }
</style>
